@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-right-color: rgb(87, 87, 87);
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
}
